<template lang="pug">
v-card-text.pt-6.px-4.flex-grow-1
          v-row
            v-col  
              v-alert(dense='' border='left' type='warning')
                | Diese Änderungen werden in allen Stellen aktualisiert
          v-row(align="center" justify="center" v-if="value")
            v-col.text-center(cols='6' sm='3')
              div(style="position: relative; width: 110px")
                v-avatar.profile-picture(size='110')

                  Avatar(:src="value ? value.photoURL : ''")
                v-btn.removeImageBtn(v-if="value && value.photoURL" fab small depressed @click="removeImage")
                  v-icon(small) mdi-close
            v-col(cols='6' sm='9')
              UploadAvatarImage(type="profileImage" @onImage="receivedImage")
            v-col(cols='12' md='6')
              v-text-field(label='Vorname' dense v-model="value.firstName" outlined hide-details required append-icon="mdi-pencil" :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']")
            v-col(cols='12' md='6')
              v-text-field(label='Nachname' dense v-model="value.lastName" outlined hide-details required append-icon="mdi-pencil" :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']")
            v-col(cols='12')
              v-text-field(label='Telefonnummer' dense v-model="value.phone" append-icon="mdi-pencil" outlined hide-details)
            v-col.px-4(cols='12')
              toggleButtons(:data='genderSelectionOptions' @setSelection="genderUpdated", :currentValue="currentGender")

</template>
<script>
import UploadAvatarImage from '@/components/dialogs/UploadAvatarImage';
import toggleButtons from '@/components/inputs/ToggleButtons';
import { Gender } from '@/types/user';
import Avatar from '@/components/elements/Avatar.vue';

window.locale = 'de';
export default {
  components: {
    UploadAvatarImage,
    toggleButtons,
    Avatar,
  },
  props: ['value'],
  data: () => ({
    genderSelectionOptions: [
      {
        title: Gender.FEMALE,
        type: Gender.FEMALE,
        icon: 'mdi-gender-female',
      },
      { title: Gender.MALE, type: Gender.MALE, icon: 'mdi-gender-male' },
      {
        title: Gender.OTHER,
        type: Gender.OTHER,
        icon: 'mdi-gender-transgender',
      },
    ],
  }),
  computed: {
    currentGender() {
      const current = this.genderSelectionOptions.findIndex(
        (item) => item.type === this.value.gender
      );
      return current == -1 ? undefined : current;
    },
  },
  methods: {
    receivedImage(url) {
      this.value.photoURL = url;
    },
    removeImage() {
      this.value.photoURL = '';
    },
    genderUpdated(gender) {
      this.value.gender = gender?.type ?? '-';
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
</style>
