<template lang="pug">
v-card-text.pt-6
          v-row
            v-col  
              v-alert(dense='' border='left' type='warning')
                | Diese Änderungen werden in allen Stellen aktualisiert
          v-row(align="center" justify="center" v-if="value")
            v-col(cols='6' sm='3')
              div(style="position: relative; width: 110px")
                v-avatar.profile-picture(size='110')
                    Avatar(:src="value ? value.logoURL : ''" :company="true")
                v-btn.removeImageBtn(v-if="value && value.logoURL" fab small depressed @click="removeImage")
                  v-icon(small) mdi-close
          
            v-col(cols='6' sm='9')
              UploadAvatarImage(type="companyLogo" @onImage="receivedImage")
            v-col(cols='12')
              v-text-field(label='Name' dense v-model="value.name" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" append-icon="mdi-pencil" required outlined hide-details)
            v-col(cols='12')
              v-text-field(label='Website' dense v-model="value.website" append-icon="mdi-pencil" outlined hide-details required)
            v-col(cols='12')
              v-textarea(label='Über uns' style="position: relative;" dense v-model="value.about" outlined hide-details)
                template(v-slot:append)
                  v-icon(style="position: absolute; top: 10px; right: 12px;") mdi-pencil
            v-col(cols='12')
              v-text-field(label='Straße' dense v-model="value.address.street" append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required outlined hide-details)
            v-col(cols='12')
              v-text-field(label='Hausnummer' dense v-model="value.address.streetNr" append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required outlined hide-details)
            v-col(cols='12')
              v-text-field(label='Postleitzahl' dense v-model="value.address.zip" append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required outlined hide-details)
            v-col(cols='12')
              v-text-field(label='Stadt' dense v-model="value.address.city" append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required outlined hide-details)

</template>
<script>
import UploadAvatarImage from '@/components/dialogs/UploadAvatarImage';
import Avatar from '@/components/elements/Avatar.vue';

export default {
  components: {
    UploadAvatarImage,
    Avatar,
  },
  props: ['value'],
  methods: {
    removeImage() {
      this.value.logoURL = '';
    },
    receivedImage(url) {
      this.value.logoURL = url;
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
.v-textarea
  .v-text-field__slot
    textarea
      padding-right: 48px !important
</style>
