<template lang="pug">
v-card-text.dialog-card-text.pt-0
        v-row.mt-5.mt-md-6
          v-col(cols="12" md="6")
            
            v-text-field(slot="inputSlot" hide-details label="Jobtitel" outlined dense v-model="value.title" :rules="[rules.required]" append-icon="mdi-pencil")

            SelectDialog.mt-md-7.mt-6(
              slot="inputSlot"
              label="Anstellungsart"
              :items='jobTypeList' 
              v-model='value.jobType'
              :required="true"
              :multiple="false"
              :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']"
            )
            DatePickerDialog.mt-md-7.mt-6(slot="inputSlot" label="Beginn" v-model="value.startDate" type="month" :required="true"   :min="currentDate" )

            SelectDialog.mt-md-7.mt-6(
              slot="inputSlot"
              label="Arbeitszeit"
              :items='workingTimeList' 
              v-model='value.workingTime'
              :required="true"
              :multiple="false"
              :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']"
            )
            
            SelectDialog.mt-md-7.mt-6(
              slot="inputSlot"
              label="Laufzeit"
              :items='durationList' 
              v-model='value.duration'
              :required="true"
              :multiple="false"
              :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']"
            )
        
            
            //- v-autocomplete( label='Staatsangehörigkeit' v-model="value.nationality" :items="nationalities" item-text="name" item-value="alpha2" return-object placeholder="Deutsch" dense outlined )
            LocationSelector.mt-md-7.mt-6(v-model="value.city" label="Ort" :required="true")
          v-col.pl-md-4(cols="12" md="6")
            SelectAreaMenu(:areaItems="value.areas" :required="true" @areaChanged="areaItemsChanged")
            //- v-autocomplete( label='Wunschort' v-model="value.city" item-value="name", item-text="name" :items="cities" dense outlined )
            

            SelectDialog.mt-md-7.mt-6(
              slot="inputSlot"
              label="Level"
              :items='experienceLevels' 
              v-model='value.level'
              :required="true"
              :multiple="false"
              :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']"
            )
            
            
            //- strong Gehaltwunsch
            SelectDialog.mt-md-7.mt-6(label="Jahresgehalt" :items='currencyItems' v-model='value.desiredSalary' formating="currency"  :multiple="false"  :required="false")

            SelectDialog.mt-md-7.mt-6(label="Jahresurlaub" :items='vacationItems' v-model='value.vacationDays' formating="vacation"  :multiple="false"  :required="false")

 
            
            SelectDialog.mt-6.mt-md-7(
              slot="inputSlot"
              label="Benefits"
              :items='benefitsList' 
              v-model='value.benefits'
              :required="false"
              :multiple="true"
            )
           
</template>

<script>
import Vue from 'vue';

import { generateArrayRange } from '@/utilities/Utils';
import InputRow from '@/components/inputs/InputRow.vue';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog.vue';
import LocationSelector from '@/components/inputs/locationSelector.vue';
import DatePickerDialog from '@/components/dialogs/DatePickerDialog.vue';
import experienceLevels from '@/assets/selections/experienceLevels.json';
import jobTypes from '@/assets/selections/jobTypes.json';
import benefits from '@/assets/selections/benefits.json';
import workingTimes from '@/assets/selections/workingTimes.json';
import workingDurations from '@/assets/selections/workingDurations.json';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';

export default Vue.extend({
  components: {
    SelectAreaMenu,
    InputRow,
    LocationSelector,
    SelectDialog,
    DatePickerDialog,
  },
  props: ['value'],
  data() {
    return {
      rules: {
        required: (value) => (!!value && value !== '') || 'Erforderlich.',
      },
      experienceLevels: experienceLevels.lookingFor,
      dialogVisible: false,
      workingTimeMenu: false,
      jobTypeList: jobTypes.offering,
      workingTimeList: workingTimes,
      durationList: workingDurations,
      benefitsList: benefits,
    };
  },
  computed: {
    vacationItems() {
      return generateArrayRange(4, 40, 1);
    },
    currencyItems() {
      return generateArrayRange(1000, 200000, 1000);
    },
    currentDate() {
      return new Date().toISOString();
    },
  },
  methods: {
    areaItemsChanged(areas) {
      this.value.areas = areas;
    },
  },
});
</script>
