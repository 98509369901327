<template lang="pug">
 v-card-text.px-4.dialog-card-text.pt-8.flex-grow-1
        InputRow(v-for="(item, index) in value" :key="index")
          v-text-field(label="Aufgabe" v-model="value[index]" hide-details dense append-outer-icon="mdi-delete" @click:append-outer="removeItem(index)" outlined slot="inputSlot")
        InputRow
          v-text-field(label="Aufgabe hinzufügen" :rules="[v => ( value.length > 0 || !!newElement) || 'Pflichtfeld']" v-model="newElement" hide-details dense append-outer-icon="mdi-plus-circle" @keydown.native.enter="addItem()" @input="changedItem()" @click:append-outer="addItem()" outlined slot="inputSlot")
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';

export default Vue.extend({
  components: { InputRow },
  props: ['value', 'initialNewElement'],
  data() {
    return {
      dialogVisible: false,
      newElement: this.initialNewElement ?? '',
    };
  },
  methods: {
    removeItem: function (i) {
      this.value.splice(i, 1);
    },
    addItem: function () {
      if (this.newElement != '') {
        this.value.push(this.newElement);
      }
      this.newElement = '';
      this.$emit('newElementChanged', '');
    },
    changedItem: function () {
      this.$emit('newElementChanged', this.newElement);
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
</style>
